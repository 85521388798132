import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import { NavContext } from "./Navigation"

const Header = ({ siteTitle, subTitle }) => {
  const [state, dispatch] = useContext(NavContext)

  const toggleNav = () => {
    dispatch({ type: "toggle" })
  }

  return (
    <header className="flex bg-gray-900 pt-4 pb-4">
      <div className="ml-4">
        <button
          onClick={toggleNav}
          className="flex items-center px-3 py-2 border rounded text-gray-100 border-gray-100 hover:border-yellow-500 hover:text-yellow-500"
        >
          <svg
            className="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div className="mx-auto">
        <h4 className="font-semibold tracking-tight">
          <Link to="/" title="Home" className="text-gray-100 no-underline">
            {siteTitle}
          </Link>
          <span className="text-base text-gray-600 ml-2 font-hairline italic tracking-tight hidden md:inline">
            {subTitle}
          </span>
        </h4>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  subTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  subTitle: ``,
}

export default Header
