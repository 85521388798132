/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./Header"
import Navigation from "./Navigation";
import "../css/main.css";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  return (
    <>
      <Navigation heading={data.site.siteMetadata.description} />
      <div className="wrapper">
        <Header siteTitle={data.site.siteMetadata.title} subTitle={data.site.siteMetadata.description} />
        {/* Body */}
        <div className="content">
          <main>
            {children}
          </main>
          <footer>
            © Jimi Smoot
          </footer>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
